.App {
  text-align: center;
}

.App-hero {
    height: 80vmin;
    pointer-events: none;
  }

.App-logo {
  height: 6vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #dae9ed;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.blurred-modal {
    backdrop-filter: blur(5px);
    background-color: rgba(#dae9ed, 0.5);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ember-modal-body {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .form-control {
    margin-bottom: 15px;
  }

  .modal-body {
    padding: 0px;
    margin: 0px;
    overflow: hidden;
  }
  .roomy-button {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .text-dark {
    color: #333;
  }

.volutia-bg-light {
    background-color: #dae9ed !important;
  }

